import React from 'react'
// import Nav from './nav'
// import Hero from '../components/Hero'
import Footer from '../components/Footer'

const Layout = ({ children }) => {

  return(
    <>
      {/* <Nav /> */}
      {/* <Hero /> */}
      {children}
      <Footer />
    </>
  )
}

export default Layout
