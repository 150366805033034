import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'


const Footer = () => {
  const data = useStaticQuery(graphql`
    query  {
      allStrapiCategory(sort: { order: ASC, fields: [order] }) {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `)
  const Categories = data.allStrapiCategory.edges
  console.log("Footer.render Categories", Categories);

  return (
    <footer className="footer">
      <div className="columns">
        <div className="column ">
          <div className="content">
            <p className="title is-5">Landgoed de Verwachting</p>
            <div className="">
              {Categories.map((edge, i) => (
                <div key={i}>
                  <Link to={`/${edge.node.slug}`} >
                    {edge.node.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="column">
          <p className="title is-5">Contact</p>
          <p><Link to="/over-ons/">Over ons</Link></p>
          <p><Link to="/contact/">Contact formulier</Link></p>
        </div>
      </div>
      <div className="content has-text-centered">
        <p>
          © {new Date().getFullYear()}, Landgoed de Verwachting
        </p>
      </div>
    </footer>
  )
}

export default Footer
